*
{
    margin: 0;
    padding: 0;
}

html
{
    background: #1e1a20;
}

/* body
{
    overflow: hidden;
} */

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}


.section
{
    display: flex;
    align-items: center;
    height: 100vh;
    position: relative;
    font-family: 'Cabin', sans-serif;
    color: #ffeded;
    text-transform: uppercase;
    font-size: 7vmin;
    padding-left: 10%;
    padding-right: 10%;
}

section:nth-child(odd)
{
    justify-content: flex-end;
}

.spaceman
{
    position: absolute;
    display: flex;
    justify-content: center;
    top: 20%;
    width: 100%;
    animation: rotation 8s infinite linear;
}

@keyframes rotation 
{
    from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }

.loading-bar 
{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #ffffff;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.5s;
    will-change: transform;
}

.loading-text
{
    position: absolute;
    width: 100%;
    top: 70%;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px;
    text-align: center;
}

.loading-bar.ended
{
    transform-origin: top right;
    transition: transform 1.5s ease-in-out;
}

.spaceman.ended{
    display: none;
}

.loading-text.ended{
    display: none;
}

.moonButton{
    text-decoration: none;
    color: white;
    background-color: #0000007c;
    padding: 5px;
    border: 1px solid white;
    border-radius: 5%;
}

.moonButton:hover{
    border: 2px solid white;
    background-color: black;
}
